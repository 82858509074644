import { initializeApp, getApp } from "firebase/app";
import {
    GoogleAuthProvider,
    getAuth,
    signInWithPopup,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signOut,
} from "firebase/auth";
import {
    getFirestore,
    query,
    getDocs,
    collection,
    where,
    addDoc,
} from "firebase/firestore";
import { getDatabase, ref, set, update } from "firebase/database";

// SETUP FIREBASE ADAPTIVE RESOURCES DEVELOPMENT CONFIG
const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSENGER_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMNT_ID
};

// init firebase services
let app;
try {
    app = getApp();
} catch (e) {
    app = initializeApp(firebaseConfig);
}

// init auth
const auth = getAuth(app);

// init firestore db
const db = getFirestore(app);

// Login handler
const logInWithEmailAndPassword = async (email, password) => {
    try {
        await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

// Register user in firebase auth handler
// FOR PRODUCTION PRE-SETUP USER LOGIN in FIREBASE DASHBOARD
const registerWithEmailAndPassword = async (email, password) => {
    try {
        await createUserWithEmailAndPassword(auth, email, password);
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

// Password reset with user email
const sendPasswordReset = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
    } catch (err) {
        console.error(err);
        alert(err.message);
    }
};

// Logout handler
const logout = () => {
    signOut(auth);
};

// Create basic user registration data
function setBasicUserData(uid, primaryContactName, contactPhoneNum, businessName, businessAddr, otherOfficeAddress, website) {
    // get realtime db
    const db = getDatabase(app);
    // add user to db
    update(ref(db, 'users/' + uid), {
        primaryContactName: primaryContactName,
        contactPhoneNum: contactPhoneNum,
        businessName: businessName,
        businessAddr: businessAddr,
        otherOfficeAddress: otherOfficeAddress,
        website: website,
        newAccountTrigger: false
    });
};

export {
    auth,
    db,
    setBasicUserData,
    logInWithEmailAndPassword,
    registerWithEmailAndPassword,
    sendPasswordReset,
    logout,
};