import React, { useState, useEffect } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import { auth } from "./Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getDatabase, ref } from "firebase/database";

function PrivateRoute({ component: Component, ...rest }) {

    const [user, loading, error] = useAuthState(auth);

    const [userType, setUserType] = useState("admin");

    const dbRef = ref(getDatabase());

    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return <h1 >Loading...</h1>
        if (!user) navigate("/login");
    }, [user, loading]);

    return (
        userType == "admin" ? <Outlet /> : navigate("/login")
    );
}

export default PrivateRoute;