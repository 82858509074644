import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Header, PrivateRoute, LoginAuth, Reset } from "./components";
import { auth } from "./components/auth/Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getDatabase, ref, child, get } from "firebase/database";
import { Registration, BasicRegistration, AdditionalRequirements } from "./views";
import { Layout } from 'antd';
import './App.css';

const { Content } = Layout;

function App() {

  //--------- Firebase Auth ---------
  const [user, loading] = useAuthState(auth);
  const dbRef = ref(getDatabase());

  //--------- State ---------
  const [section, setSection] = useState('Registration Portal')
  const [userType, setUserType] = useState();

  //--------- Get User Info from 'Users' database, after sign in ---------
  useEffect(() => {
    if (loading) return <h2 >Loading...</h2>;
    if (!user) return <Link to="/login"></Link>;

    get(child(dbRef, `users/${user.uid}`))
      .then((snapshot) => {
        if (snapshot.exists()) {
          if (snapshot.val().newAccountTrigger == false) return window.location.replace('https://app.adaptiveresources.io/profile');
          setUserType(snapshot.val().userType);
        } else {
          alert("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });

  }, [user, loading]);

  return (
    <div className="App">
      <Router >
        <Header section={section} />
        <Layout >
          <Content >
            <Routes>
              {/* 🌐 Public Routes*/}
              <Route exact path="/login" element={<LoginAuth />} />
              <Route exact path="/reset" element={<Reset />} />
              {/* 🗝️ Private Routes */}
              <Route exact path='/' element={<PrivateRoute />}>
                <Route exact path="/" element={<Registration
                  userType={userType}
                />} />
              </Route>
              <Route exact path='/basic-registration' element={<PrivateRoute />}>
                <Route exact path="/basic-registration" element={<BasicRegistration
                />} />
              </Route>
              <Route exact path='/additional-requirements' element={<PrivateRoute />}>
                <Route exact path="/additional-requirements" element={<AdditionalRequirements
                />} />
              </Route>
            </Routes>
          </Content>
        </Layout>
      </Router>
    </div>
  );
}

export default App;
