import { Row, Col } from "antd";
import { Link } from "react-router-dom";

export default function Header({ section }) {
    return (
        <Row align="middle" style={{ backgroundColor: "#203864", height: '11vh' }}>
            <Col flex={2}>
                <Link to="." >
                    <img
                        className="header-container"
                        src="Adaptive_Logo_Slash_LightGray.png"
                    />
                </Link>
            </Col>
            <Col flex={20}></Col>
            <Col flex={2}>
                <p style={{ color: "#cccccc", padding: '0px', margin: '0px' }}>
                    {section}
                </p>
            </Col>
        </Row>
    )
}