import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth, setBasicUserData } from "../components/auth/Firebase";
import { getDatabase, ref, onValue } from "firebase/database";
import { Form, Input, Card, Row, Col, Button, Modal } from "antd";
import { ArrowRightOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

//DEBUG
const DEBUG = false;

export default function BasicRegistration({ }) {

    const navigate = useNavigate();

    const [form] = Form.useForm();

    // ===== user auth state =====
    const [user, loading] = useAuthState(auth);

    // ===== Realtime DB =====
    const db = getDatabase();
    const dbRef = ref(getDatabase());

    // ========== local state ==========
    const [sending, setSending] = useState();
    const [primaryContactName, setPrimaryContactName] = useState();
    const [contactPhone, setContactPhone] = useState();
    const [businessName, setBusinessName] = useState();
    const [businessAddr, setBusinessAddr] = useState();
    const [otherOfficeAddress, setOtherOfficeAddress] = useState("");
    const [website, setWebsite] = useState();
    const [modalVisible, setModalVisible] = useState();

    // ========== Form Methods ==========
    // Handle Failed
    const onFinishFailed = async (errorInfo) => {
        console.log("Failed", form.getFieldsError)
    }

    const handleCancel = () => {
        setModalVisible(false);
    };

    // ===== HANDLE SUBMIT =====
    const handleBasicRegistrationSubmit = async () => {
        // set basic user info in Firebase
        setBasicUserData(user.uid, primaryContactName, contactPhone, businessName, businessAddr, otherOfficeAddress, website)
        // display redirect modal 
        setModalVisible(true)
    }

    return (
        <div style={{ height: '89vh', marginTop: '30px' }}>
            <Row justify="center">
                <Col xs={0} sm={0} md={1} lg={2} xl={5}></Col>
                <Col xs={23} sm={23} md={22} lg={18} xl={14}>
                    <Card
                        hoverable={true}
                        style={{ background: '#d9d9d950', borderRadius: '18px' }}
                    >
                        <h2 style={{ paddingBottom: '18px' }}>Basic Registration</h2>
                        <Form
                            className="create-claim-form"
                            form={form}
                            layout="horizontal"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 18 }}
                            onFinish={handleBasicRegistrationSubmit}
                            onFinishFailed={onFinishFailed}
                            requiredMark={false}
                            labelAlign="right"
                            labelWrap
                            colon={false}
                        >
                            <Form.Item
                                name={'primary-contact-name'}
                                label={'Primary Contact Person'}
                                rules={[
                                    {
                                        required: true
                                    },
                                    {
                                        pattern: /^[a-zA-Z0-9_ ]*$/,
                                        message: 'Not a valid input.',
                                    }
                                ]}>
                                <Input onChange={(e) => {
                                    setPrimaryContactName(e.target.value)
                                }}
                                    style={{ width: '100%' }}
                                    placeholder={"Name"}
                                />
                            </Form.Item>
                            <Form.Item
                                name={'primary-contact-phone'}
                                label={'Primary Contact Phone'}
                                rules={[
                                    {
                                        required: true
                                    },
                                    {
                                        pattern: /^[ A-Za-z0-9_@./#&+-]+$/,
                                        message: 'Not a valid input.',
                                    }
                                ]}>
                                <Input onChange={(e) => {
                                    setContactPhone(e.target.value)
                                }}
                                    type='tel'
                                    style={{ width: '100%' }}
                                    placeholder={"Phone Number"}
                                />
                            </Form.Item>
                            <Form.Item
                                name={['business-name']}
                                label={"Business Name"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: /^[a-zA-Z0-9_ ]+$/,
                                        message: 'Not a valid input.',
                                    },
                                ]}
                            >
                                <Input onChange={(e) => {
                                    setBusinessName(e.target.value)
                                }}
                                    placeholder="Business Name"
                                />
                            </Form.Item>
                            <Form.Item
                                name={['business-address']}
                                label="Registered/Business Address"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: /^[ A-Za-z0-9_@.,/#&+-]+$/,
                                        message: 'Not a valid input.',
                                    }
                                ]}
                            >
                                <Input onChange={(e) => {
                                    setBusinessAddr(e.target.value)
                                }}
                                    style={{ width: '100%' }}
                                    placeholder={"Registered/Business Address (if different, please provide both)"}
                                />
                            </Form.Item>
                            <Form.Item
                                name={['other-address']}
                                label="Address(es) of Other Offices and Plants"
                                rules={[
                                    {
                                        required: false,
                                    },
                                    {
                                        pattern: /^[ A-Za-z0-9_@.,/#&+-]+$/,
                                        message: 'Not a valid input.',
                                    }
                                ]}
                            >
                                <Input onChange={(e) => {
                                    setOtherOfficeAddress(e.target.value)
                                }}
                                    style={{ width: '100%' }}
                                    placeholder={"Address(es) of Other Offices and Plants (if applicable)"}
                                />
                            </Form.Item>
                            <Form.Item
                                name={['website']}
                                label="Website"
                                rules={[
                                    {
                                        required: true,
                                    },
                                    {
                                        pattern: /^[A-Za-z0-9_.:/#&+-]+$/,
                                        message: 'Not a valid input.',
                                    }
                                ]}
                            >
                                <Input onChange={(e) => {
                                    setWebsite(e.target.value)
                                }}
                                    style={{ width: '100%' }}
                                    placeholder={"Website"}
                                />
                            </Form.Item>
                            <Row justify="center">
                                <div className="create-claim-form">
                                    By clicking confirm, you agree to our <a href="https://files.gitbook.com/v0/b/gitbook-x-prod.appspot.com/o/spaces%2FFP4STV8Vm5X3jwHoOxrN%2Fuploads%2FNVF5pluL6YWv9P80kMYG%2FAdaptive%20Resources%20User%20Agreement%20September%2016%202022%20V1.docx.pdf?alt=media&token=36216c67-080e-4054-ae28-0cc6fb5e1d46" target='blank'>User Agreement</a>.
                                </div>
                            </Row>
                            <Button
                                type="primary"
                                shape="round"
                                loading={sending}
                                htmlType="submit"
                                style={{ margin: '24px' }}
                                disabled={!primaryContactName || !contactPhone || !businessName || !businessAddr || !website}
                            >
                                Confirm
                            </Button>
                        </Form>
                    </Card>
                </Col>
                <Col xs={0} sm={0} md={1} lg={2} xl={5}></Col>
            </Row>
            <Row justify="center">
                <div className="create-claim-form" style={{ marginTop: '16px', fontSize: '16px' }}>
                    For help, please see the <a href="https://adaptive-resources.gitbook.io/adaptive-resources-docs/user-guides/registration" target='blank'>Registration Guide</a>.
                </div>
            </Row>
            <Modal
                open={modalVisible}
                onCancel={handleCancel}
                width='45%'
                footer={null}
            >
                <div style={{ fontSize: '115%' }}>
                    <Row justify="center">
                        <Row justify="center"><p style={{ marginBottom: '28px', color: '#4caf50' }}>Basic registration complete!</p></Row>
                        <Row justify="center">
                            <img src="Adaptive_Logo_Slash_LightGray.png" width={'50%'} style={{ marginBottom: '28px' }} />
                        </Row>
                        <a href='https://app.adaptiveresources.io/profile'>Return to application <ArrowRightOutlined /></a>
                    </Row>
                </div>
            </Modal>
        </div >
    );
}