import React, { useState } from "react";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";

//DEBUG
const DEBUG = false;

export default function Registration({ userType }) {

    return (
        <div style={{ height: '89vh' }}>
            <Row style={{ height: '150px' }}></Row>
            <Row type="flex" justify="center">
                <Col >
                    <h2 style={{ fontWeight: 'bold', color: '#575757', marginBottom: '20px' }}>Assigned User Type</h2>
                    <div className='user-type-container'>
                        {userType == "admin" ? (
                            <Row justify="space-evenly">
                                <Link to={'/basic-registration'}>
                                    <button className="user-type-button">
                                        Admin
                                    </button>
                                </Link>
                            </Row>
                        ) : ("")}
                        {userType == "buyer" ? (
                            <Row justify="space-evenly">
                                <Link to={'/basic-registration'}>
                                    <button className="user-type-button">
                                        Downstream User/Claim Buyer
                                    </button>
                                </Link>
                            </Row>
                        ) : ("")}
                        {userType == "beneficiary_ngo" ? (
                            <Row justify="space-around">
                                <Link to={'/basic-registration'}>
                                    <button className="user-type-button">
                                        NGO or Other Impact Beneficiary
                                    </button>
                                </Link>
                            </Row>
                        ) : ("")}
                        {userType == "creator" ? (
                            <Row justify="space-around">
                                <Link to={'/basic-registration'}>
                                    <button className="user-type-button">
                                        Exporter or Other Claim Creator
                                    </button>
                                </Link>
                            </Row>
                        ) : ("")}
                        {userType == "auditor" ? (
                            <Row justify="space-around">
                                <Link to={'/basic-registration'}>
                                    <button className="user-type-button">
                                        Auditor or Validator
                                    </button>
                                </Link>
                            </Row>
                        ) : ("")}
                    </div>
                </Col>
            </Row >
        </div >
    );
}