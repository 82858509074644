import React, { useEffect, useState } from "react";
import { Button, Card, Row, Col, Input } from "antd";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth, sendPasswordReset } from "./Firebase";
import "./Reset.css";


function Reset() {

    const [email, setEmail] = useState("");

    const [user, loading, error] = useAuthState(auth);

    const navigate = useNavigate();

    useEffect(() => {
        if (loading) return <h1 >Loading...</h1>;
        if (user) navigate("/");
    }, [user, loading]);
    return (
        <div className="reset">
            <Row justify="center">
                <Col xs={23} sm={22} md={12} lg={10} xl={10}>
                    <Card >
                        <p >An email will be sent to the email address provided with instructions on resetting your password.</p>
                        <Row justify='center'>
                            <Input
                                type="text"
                                className="reset__textBox"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="E-mail Address"
                            />
                        </Row>
                        <Button
                            style={{ fontSize: "16px", marginTop: '10px' }}
                            shape='round'
                            size='large'
                            type='primary'
                            onClick={() => sendPasswordReset(email)}
                        >
                            Reset
                        </Button>
                        <div >
                            <Link to={"/login"}>
                                <Button
                                    style={{ fontSize: "14px", marginTop: '10px' }}
                                    shape="round"
                                >
                                    Back
                                </Button>
                            </Link>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div >
    );
}
export default Reset;