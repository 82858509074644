import { useEffect, useState } from "react";
import { Button, Card, Row, Form, Input, Col } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword } from "./Firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css";

const LoginAuth = () => {

    useEffect(() => {
        if (user) navigate("/");
    });

    const [form] = Form.useForm();
    // Form Layout
    const layout = {
        labelCol: {
            span: 6,
        },
        wrapperCol: {
            span: 24
        }
    };

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [user, loading, error] = useAuthState(auth);

    const navigate = useNavigate();

    useEffect(() => {
        if (loading) {
            return <h2 >Loading...</h2>;
        }
        if (user) navigate("/");
    }, []);

    const handleSubmit = async () => {
        await logInWithEmailAndPassword(email, password);
    }

    const onFailed = async (errorInfo) => {
        console.log("Failed", form.getFieldsError);
        console.log(errorInfo);
    }

    return (
        <div className="login__container">
            <Row justify="center">
                <Col xs={23} sm={22} md={12} lg={10} xl={8}>
                    <Card style={{ borderRadius: '24px' }}>
                        <Form
                            form={form}
                            {...layout}
                            onFinish={handleSubmit}
                            onFinishFailed={onFailed}
                            requiredMark={false}
                            colon={false}
                        >
                            <Form.Item
                                name={['email']}
                                label={'Email'}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}>
                                <Input
                                    type="text"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="E-mail Address"
                                />
                            </Form.Item>
                            <Form.Item
                                name={['password']}
                                label={'Password'}
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}>
                                <Input
                                    type="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Password"
                                />
                            </Form.Item>

                            <Form.Item >
                                <Button
                                    size="large"
                                    shape="round"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Login
                                </Button>
                            </Form.Item>
                        </Form>

                        <div>
                            <Link to="/reset">Forgot Password</Link>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default LoginAuth;