import React, { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../components/auth/Firebase";
import { getDatabase, ref } from "firebase/database";
import { Form, Input, Card, Row, Col, Button, Modal } from "antd";
import { ArrowRightOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";

//DEBUG
const DEBUG = false;

export default function AdditonalRequirements({ }) {

    const navigate = useNavigate();

    const [form] = Form.useForm();

    // ===== user auth state =====
    const [user, loading] = useAuthState(auth);

    // ===== Realtime DB =====
    const db = getDatabase();
    const dbRef = ref(getDatabase());

    // ========== local state ==========
    const [dateOfIncorporation, setDateOfIncorporation] = useState();
    const [incorporationCountry, setIncorporationCountry] = useState();
    const [incorporationState, setIncorporationState] = useState();
    const [businessRegNumber, setBusinessRegNumber] = useState();
    const [natIdNumber, setNatIdNumber] = useState();
    const [taxIdNumber, setTaxIdNumber] = useState();
    const [modalVisible, setModalVisible] = useState();

    // ========== Form Methods ==========
    // Handle Failed
    const onFinishFailed = async (errorInfo) => {
        console.log("Failed", form.getFieldsError)
    }

    const handleCancel = () => {
        setModalVisible(false);
    };

    // ===== HANDLE SUBMIT =====
    const handleCreateClaimSubmit = async () => {
        console.log("hi");

        setModalVisible(true)
    }

    return (
        <div style={{ height: '89vh', marginTop: '30px' }}>
            <Row justify="center">
                <Col xs={0} sm={0} md={1} lg={2} xl={5}></Col>
                <Col xs={23} sm={23} md={22} lg={18} xl={14}>
                    <Card
                        hoverable={true}
                        style={{ background: '#d9d9d950', borderRadius: '18px' }}
                    >
                        <h2 style={{ paddingBottom: '18px' }}>Additional Requirements</h2>
                        <Form
                            className="create-claim-form"
                            form={form}
                            layout="horizontal"
                            labelCol={{ span: 8 }}
                            wrapperCol={{ span: 18 }}
                            onFinish={handleCreateClaimSubmit}
                            onFinishFailed={onFinishFailed}
                            requiredMark={false}
                            labelAlign="right"
                            labelWrap
                            colon={false}
                        >
                            <Form.Item
                                name={['incorporation-date']}
                                label={"Date of Incorporation"}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input onChange={(e) => {
                                    setDateOfIncorporation(e.target.value)
                                }}
                                    type='date'
                                    placeholder="Incorporation Date"
                                />
                            </Form.Item>
                            <Form.Item
                                name={['incorporation-country']}
                                label="Country of Incorportion"
                                rules={[
                                    {
                                        required: true,
                                    }
                                ]}
                            >
                                <Input onChange={(e) => {
                                    setIncorporationCountry(e.target.value)
                                }}
                                    style={{ width: '100%' }}
                                    placeholder={"Country of Incorporation"}
                                />
                            </Form.Item>
                            <Form.Item
                                name={['incorporation-state']}
                                label="State of Incorportion"
                                rules={[
                                    {
                                        required: true,
                                    }
                                ]}
                            >
                                <Input onChange={(e) => {
                                    setIncorporationState(e.target.value)
                                }}
                                    style={{ width: '100%' }}
                                    placeholder={"State of Incorporation"}
                                />
                            </Form.Item>
                            <Form.Item
                                name={['business-registration-number']}
                                label="Business Registration Number"
                                rules={[
                                    {
                                        required: true,
                                    }
                                ]}
                            >
                                <Input onChange={(e) => {
                                    setBusinessRegNumber(e.target.value)
                                }}
                                    style={{ width: '100%' }}
                                    placeholder={"Business Registration Number"}
                                />
                            </Form.Item>
                            <Form.Item
                                name={'national-identification-number'}
                                label={'National Identification Number'}
                                rules={[
                                    {
                                        required: true
                                    },
                                    {
                                        pattern: /^[a-z0-9A-Z]+$/,
                                        message: 'Not a valid input.',
                                    }
                                ]}>
                                <Input onChange={(e) => {
                                    setNatIdNumber(e.target.value)
                                }}
                                    style={{ width: '100%' }}
                                    placeholder={"ID"}
                                />
                            </Form.Item>
                            <Form.Item
                                name={'tax-identification-number'}
                                label={'Tax Identification Number'}
                                rules={[
                                    {
                                        required: true
                                    },
                                    {
                                        pattern: /^[a-z0-9A-Z]+$/,
                                        message: 'Not a valid input.',
                                    }
                                ]}>
                                <Input onChange={(e) => {
                                    setTaxIdNumber(e.target.value)
                                }}
                                    style={{ width: '100%' }}
                                    placeholder={"ID"}
                                />

                            </Form.Item>
                            <Button
                                type="primary"
                                shape="round"
                                htmlType="submit"
                                disabled={!dateOfIncorporation || !incorporationCountry || !incorporationState || !businessRegNumber || !natIdNumber || !taxIdNumber}
                            >
                                Submit
                            </Button>
                        </Form>
                    </Card>
                </Col>
                <Col xs={0} sm={0} md={1} lg={2} xl={5}></Col>
            </Row>
            <Row justify="center">
                <div className="create-claim-form" style={{ margin: '30px', fontSize: '16px' }}>
                    For help, please see the <a href="https://adaptive-resources.gitbook.io/adaptive-resources-docs/user-guides/registration" target='blank'>Registration Guide</a>.
                </div>
            </Row>
            <Modal
                open={modalVisible}
                onCancel={handleCancel}
                width='45%'
                footer={null}
            >
                <div style={{ fontSize: '115%' }}>
                    <Row justify="center">
                        <Row justify="center"><p style={{ marginBottom: '28px', color: '#68676799' }}>Profile updated, thank you!</p></Row>
                        <Row justify="center">
                            <img src="Adaptive_Logo_Slash_LightGray.png" width={'50%'} style={{ marginBottom: '28px' }} />
                        </Row>
                        <a href='https://app.adaptiveresources.io/profile'>Return to application <ArrowRightOutlined /></a>
                    </Row>
                </div>
            </Modal>
        </div >
    );
}